@import "colors";
@import "misc";

body {
  background: $backgroundColor;
  margin: 0;
  padding-top: 12rem;
  padding-top: calc(35px + 120px);  // .top-bar height + .navigation height
  @media (max-width: $tablet) { padding-top: calc(35px + 74.13px); }  // .top-bar height + .navigation height

  // font default settings
  color: $defaultFontColor;
  font-size: 16px;
  line-height: 1.8;
  letter-spacing: 0.03rem;

  @media (max-width: $mobile) {
    font-size: 12px;
    // padding-top: 8rem;
  }

  a {
    text-decoration: none;
    color: $secondaryColor;
  }
  h1 {
    margin-top: 4rem;
    margin-bottom: 0;
    font-weight: normal;
    @media (max-width: $mobile) {
      font-size: 18,4px;
      margin-top: 2rem;
    }
  }

  h2 {
    color: $primaryColor;
    margin-top: 4rem;
    margin-bottom: 0;
    font-weight: normal;
    @media (max-width: $mobile) {
      font-size: 16px;
      margin-top: 2rem;
    }
  }
  h3{
    font-weight: normal;
    margin-top: 4rem;
    margin-bottom: 0;
    color: $primaryColor;
    font-size: 2rem;
    font-weight: 400;
    line-height: 1.6;
    @media (max-width: $mobile) {
      font-size: 14,4px;
      margin-top: 2rem;
    }
  }

  .default-container {
    padding-top: 4rem;
    padding-bottom: 4rem;
    display: flex;
    width: 100%;
    justify-content: center;

    @media (max-width: $mobile) {
      padding-top: 1rem;
      padding-bottom: 1rem;
    }
  
    .wrapper {
      flex-basis: 70rem;
      padding: 0 1rem;
    }
  }
  
}
.btn {
  background-color: $secondaryColor;
  font-size: 11,2px;
  margin-bottom: 2rem;
  text-transform: uppercase;
  letter-spacing: .075rem;
  border-radius: 6px;
  transition: all .2s;
  line-height: 20px;
  font-weight: 400;
  color: white;
  padding: 12px 16px;
  min-width: 140px;
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
  @media (max-width: $mobile) {
    font-size: 9,6px;
    margin-bottom: 1rem;
  }

  &:hover {
    opacity: .9;;
  }

  &-big {
    @extend .btn;
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: normal;
    @media (min-width: $tablet) {
      font-size: 14,4px;
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
    }
    & *{
      margin-inline-start: 1rem;
      margin-inline-end: 1rem;
    }
  }
}

.bg-primary{
  background-color: $primaryColor;
}

.bg-secondary{
  background-color: $secondaryColor;
}

.blue-box{
  background-color: $secondaryColor;
  color: #ffffff;
  padding-top: 4rem;
  padding-bottom: 6.25em;
  padding-left: 1rem;
  padding-right: 1rem;

  .default-container{
    padding:0;
  }

  @media (max-width: $mobile) {
    padding-top: 1rem;
    padding-bottom: 3rem;
  }
}

.white-box{
  background-color: #ffffff;
  color: $secondaryColor;
}

.center{
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
}

.block{
  display: block;
}

.title-image {
  width: 70%;
}

.large {
  font-size: 200%;
}

.orange {
  color: $primaryColor;
}

.row-container{
  display: flex;
  flex-direction: row;
  padding-top: 5rem;

  @media (max-width: $mobile) {
    &.mobile-column {
      flex-direction: column;
    }
  }
}

.two-columns {
  padding: 4rem;
  display: flex;
  width: 50%;
  align-items: center;
  flex-direction: column;
}

.shadow-box, .box {
  padding: 1rem;
  display: flex;
  flex: 1;
  align-items: center;
  flex-direction: column;

  box-shadow: 3px 5px 6px #ccc;
  border-radius: 20px;
  border: 1px solid rgb(226, 226, 226);

  &:not(:first-child){
    margin-inline-start: 0.3rem;
    
    @media (max-width: $mobile) {
      margin-inline-start:0;
      margin-top:0.7rem;
    }
  }
  &:not(:last-child){
    margin-inline-end: 0.3rem;
    
    @media (max-width: $mobile) {
      margin-inline-end:0;
      margin-bottom:0.7rem;
    }
  }

  p{
    margin: 0;
    line-height: 1.5;
    text-align: justify;
  }

  .title{
    width: 100%;
    font-weight: bold;
  }

  .icon{
    max-height: 200px;
    max-width: 100%;
    @media (max-width: $mobile) {
      max-height: 170px;
    }
  }
}

.box {
  box-shadow: none;
  border: none;
}

.margin-bottom {
  margin-bottom: 2rem;
}

table.multi-lang {
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;

  & > tbody > tr {
    & > td:not(:last-child) {
      border-inline-end: 2px solid rgba(0,0,0,0.2);
    }

    & > td {
      vertical-align: top;
      text-align: start;
      padding: 0 2rem;
      padding-bottom: 1rem;
      line-height: 1.2;

      @media (max-width: $mobile){
        padding: 0;
        padding-bottom: 1rem;
      }

      dl {
        margin-block-end: 0;
      }
    }
  }


}

table {
  &.simple {
    border-collapse: collapse;
    width: 100%;
  
    td,th {
      border: 1px solid black;
      padding: 0.3rem;
      word-wrap: break-word;
      overflow-wrap: break-word;

      @media (max-width: $mobile) {
        padding: 0.2rem;
      }
    }

    th {
    text-align: center;
    }
  }

  &.col1-bold {
    td:first-child {
    font-weight: bold;
    }
  }
}

.hidden {
  display: none;
}
